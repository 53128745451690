import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import WideNav from '../views/WideNav';
import NarrowNav from '../views/NarrowNav';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/wide-nav',
    name: 'WideNav',
    component: WideNav
  },
  {
    path: '/narrow-nav',
    name: 'NarrowNav',
    component: NarrowNav
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
