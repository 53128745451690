import Vue from 'vue';
import CSDesignSystem, {
  vuetify,
  i18n,
  overrideLoginTranslations
} from '@complispace/cs-design-system';
import App from './App';
import './registerServiceWorker';
import router from './router';
import store from './store';
import SlotIndicator from './components/SlotIndicator';

Vue.config.productionTip = false;

const options = {
  store,
  router,
  auth0: {
    appHost: window.location.host,
    appUrl: window.location.origin,
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    appEnv: 'develop'
  }
};

// Complispace design system
Vue.use(CSDesignSystem, options);

// Internationalization
const { messages } = i18n;
Object.keys(messages).forEach(key => {
  const appHostSplits = window.location.host.split('.');
  const combined = messages[key];
  const overridden = overrideLoginTranslations(combined, {
    loginViewSubTitle: `Log in to ${appHostSplits[0].toUpperCase() ||
      ''} CS Design System Documentation Site below`
  });

  i18n.setLocaleMessage(key, overridden);
});

Vue.component('slot-indicator', SlotIndicator);

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
